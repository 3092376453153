import React from 'react';
import { Box, Typography, Container, useMediaQuery, useTheme } from '@mui/material';
import './style.css';

const AboutUs = ({ aboutRef }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const lineHeight = '2px'; // Adjust this value to control the thickness of the line
  const lineColor = '#8522B9'; // Purple color for the line

  return (
    <Container
      ref={aboutRef}
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        mt: isSmallScreen ? -3 : -2.5,
        py: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          mb: 4,
          width: '100%',
          textAlign: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ flexGrow: 1, height: lineHeight, backgroundColor: lineColor }} />
        <Typography variant={isSmallScreen ? 'h5' : 'h4'} color="#8522B9" fontWeight="bold" sx={{ mx: 2, whiteSpace: 'nowrap' }}>
          About Us
        </Typography>
        <Box sx={{ flexGrow: 1, height: lineHeight, backgroundColor: lineColor }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 4,
          textAlign: 'justify',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          width: '100%',
          py: 2,
          px: isSmallScreen ? 2 : 0,
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            mb: isSmallScreen ? 4 : 0,
          }}
        >
          <img
            id="logo-image"
            src='/assets/logo.svg'
            alt='logo'
            style={{
              width: isSmallScreen ? '10rem' : '14rem',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: isSmallScreen ? '100%' : '60%',
            maxWidth: 800,
          }}
        >
          <Typography variant="h6" sx={{ mb: 3 }}>
            At Purple Block, we want to democratize access to Data Science & Artificial Intelligence. Our mission is to help businesses solve their most pressing problems using AI, preparing them for efficiency & scale.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            In a world increasingly driven by data, we see a vast untapped potential. Businesses can leverage AI and analytics to unlock groundbreaking solutions. The challenge lies in making these tools accessible and understandable. Purple Block bridges that gap.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            At Purple Block, we don't just offer services, we forge partnerships with our clients, customizing solutions that address their unique needs.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            While you focus on the core of your business, we focus on empowering everyone in the organization to harness the power of data & AI for higher efficiency and better decision making.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutUs;
