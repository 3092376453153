import React, { useState } from "react";
import axios from "axios";
import { API_ROUTES } from "../../constant/apiConstant";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Services = ({ serviceRef }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const lineHeight = "2px"; // Adjust this value to control the thickness of the line
  const lineColor = "#8522B9"; // Purple color for the line

  const [isHover, setIsHover] = useState({
    appliedAIHover: false,
    dataManagementHover: false,
    appliedAIHoverKey: 0,
    dataManagementHoverKey: 0,
  });

  const {
    appliedAIHover,
    dataManagementHover,
    appliedAIHoverKey,
    dataManagementHoverKey,
  } = isHover;

  const appliedAiServices = [
    {
      label: "Machine Learning & Predictive Analytics",
      paraText:
        "We develop sophisticated algorithms that learn from data to improve decision-making and predict future trends, including tailored AI solutions like recommendation systems, chatbots, and anomaly detection.",
      iconSrc: "/assets/Frame 1.svg",
    },
    {
      label: "Generative AI & LLM Agents",
      paraText:
        "Use the power of generative AI to empower customers, employees, and other stakeholders with tools that help everyone gather information quickly & easily.",
      iconSrc: "/assets/Frame 2.svg",
    },
    {
      label: "Natural Language Processing & Computer Vision Solutions",
      paraText:
        "We create advanced NLP and computer vision applications for text analysis, image recognition, and speech processing to enhance user experiences and automate complex tasks.",
      iconSrc: "/assets/Frame 3.svg",
    },
    {
      label: "Cloud Deployment & Integration",
      paraText:
        "Streamline your AI and ML operations on various cloud platforms like AWS, Azure, and GCP. We specialize in optimizing MLOps and LLMOps workflows, ensuring seamless, scalable, and secure solutions.",
      iconSrc: "/assets/Frame 4.svg",
    },
  ];

  const dataManagementServices = [
    {
      label: "Data Collection & Architecture",
      paraText:
        "We design robust data architectures that ensure seamless data collection, storage, and integration, providing a solid foundation for scalable and secure data management.",
      iconSrc: "/assets/Frame 5.svg",
    },
    {
      label: "Data Processing & Management",
      paraText:
        "We implement advanced data processing pipelines and management strategies to ensure data quality, consistency, and accessibility across your organization.",
      iconSrc: "/assets/Frame 6.svg",
    },
    {
      label: "Data Analysis, Visualization & Storytelling",
      paraText:
        "We transform raw data into actionable insights through sophisticated analysis, intuitive visualizations, and compelling narratives that drive informed decision-making.",
      iconSrc: "/assets/Frame 7.svg",
    },
    {
      label: "Business Automation & Process Optimization",
      paraText:
        "We leverage data-driven automation tools to streamline business processes, reduce operational inefficiencies, and enhance productivity through optimized workflows.",
      iconSrc: "/assets/Frame 8.svg",
    },
  ];

  const handleMouseOverCapture = (idx, dataManagement) => {
    if (dataManagement) {
      setIsHover({
        ...isHover,
        dataManagementHover: true,
        dataManagementHoverKey: idx,
      });
    } else {
      setIsHover({ ...isHover, appliedAIHover: true, appliedAIHoverKey: idx });
    }
  };

  const handleMouseOutCapture = () => {
    setIsHover({
      ...isHover,
      appliedAIHover: false,
      dataManagementHover: false,
    });
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhoneNumber(value);

      if (value.length === 0) {
        setMessage("");
      } else if (value.length === 10) {
        setMessage("Contact information submitted successfully.");
      } else {
        setMessage("Mobile number must be exactly 10 digits.");
      }
    } else {
      setMessage("Only digits are allowed (max 10).");
    }
  };

  const handleSubmit = async () => {
    if (phoneNumber.length !== 10) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      const res = await axios.post(API_ROUTES.GET_MOBILE_NUMBER, {
        phone: phoneNumber,
      });
      console.log("Response:", res);
      if (res.data) {
        setPhoneNumber("");
        alert(message);
      }
    } catch (error) {
      console.error(error.message);
      alert(`An error occurred: ${error.message}`);
    }
  };

  const renderServiceCard = (service, idx, isDataManagement) => {
    const { label, paraText, iconSrc } = service;
    const isHovered = isDataManagement
      ? dataManagementHover && dataManagementHoverKey === idx
      : appliedAIHover && appliedAIHoverKey === idx;

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
        <Card
          onMouseOver={() => handleMouseOverCapture(idx, isDataManagement)}
          onMouseOut={handleMouseOutCapture}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            color: "white",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
            borderRadius: 2,
            cursor: "pointer",
            boxShadow: 3,
            transition: "all 0.3s ease",
          }}
        >
          <CardActionArea
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <CardContent
              sx={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: isHovered ? "flex-start" : "space-between",
                alignItems: "center",
                position: "relative",
                padding: isHovered ? "16px" : "16px 16px 24px 16px",
              }}
            >
              {!isHovered && (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50%"
                  >
                    <img
                      src={iconSrc}
                      alt="service icon"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50%"
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{
                        fontSize: isSmallScreen ? "1rem" : "0.9rem",
                        fontWeight: isHovered ? "800" : "normal", // Make label bold on hover
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                </>
              )}
              {isHovered && (
                <>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      fontSize: isSmallScreen ? "1rem" : "0.9rem",
                      marginBottom: "1rem",
                      fontWeight: "800", // Bold label when hovered
                    }}
                  >
                    {label}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {paraText}
                  </Typography>
                </>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  return (
    <Container ref={serviceRef} sx={{ py: 6 }}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <Box
          sx={{ flexGrow: 1, height: lineHeight, backgroundColor: lineColor }}
        />
        <Typography
          variant={isSmallScreen ? "h5" : "h4"}
          color="#8522B9"
          fontWeight="bold"
          sx={{ mx: 2, whiteSpace: "nowrap" }}
        >
          Services
        </Typography>
        <Box
          sx={{ flexGrow: 1, height: lineHeight, backgroundColor: lineColor }}
        />
      </Box>

      <Box sx={{ textAlign: "center", mb: 6 }}>
        <img
          src="/assets/appliedAI.svg"
          alt="Applied AI"
          style={{ width: "10rem", marginBottom: "0.25rem" }}
        />
        <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom>
          Applied AI
        </Typography>
        <Typography variant="body1" maxWidth={800} mx="auto">
          We use advanced technologies like AI & ML and make it accessible and
          easy to use for you and your team.
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="center" mb={6}>
        {appliedAiServices.map((service, idx) =>
          renderServiceCard(service, idx, false)
        )}
      </Grid>

      <Box sx={{ textAlign: "center", mb: 6 }}>
        <img
          src="/assets/data-stragy.svg"
          alt="Data Strategy"
          style={{ width: "10rem", marginBottom: "0.25rem" }}
        />
        <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom>
          Data Strategy & Management
        </Typography>
        <Typography variant="body1" maxWidth={800} mx="auto">
          We help businesses develop clear roadmaps for managing and utilizing
          their data effectively to drive growth and innovation.
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="center" mb={6}>
        {dataManagementServices.map((service, idx) =>
          renderServiceCard(service, idx, true)
        )}
      </Grid>

      <Box sx={{ textAlign: "center", mt: 6 }}>
        <Typography variant="h5" gutterBottom>
          Unsure of how we can help?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Enter your contact below and we will be in touch within 24 hours! 👇
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxWidth={400}
          mx="auto"
          mt={2}
          mb={2}
        >
          <TextField
            fullWidth
            placeholder="Enter Your Phone Number"
            value={phoneNumber}
            onChange={handleChange}
            variant="outlined"
            sx={{
              flexGrow: 1,
              mr: 2,
              backgroundColor: "white",
              borderRadius: 1,
            }}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
        {message && (
          <Typography variant="body2" color="textSecondary">
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default Services;
